<template>
  <v-app>
    <alumno-core-app-bar />

    <alumno-core-drawer />

    <alumno-core-view />

    <alumno-core-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'AlumnoIndex',

    components: {
      AlumnoCoreAppBar: () => import('./components/core/AppBar'),
      AlumnoCoreDrawer: () => import('./components/core/Drawer'),
      AlumnoCoreView: () => import('./components/core/View'),
      AlumnoCoreFooter: () => import('./components/core/Footer'),
    },
  }
</script>
